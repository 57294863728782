import React, { useContext, useEffect } from 'react';
import { useLocation } from '@reach/router';

import { useMutation } from '@apollo/react-hooks';
import { navigate, Link } from 'gatsby';
// components
import Loader from '@components/loader';
// queries
import { MAGIC_SIGN_IN, PROMOTE_GUEST_CONSULTATION } from '@queries/user';
import { BASKET_MERGE_GUEST } from '@queries/basket';
// store
import { GlobalContext } from '@store/global-state';
import { ERROR_MESSAGES } from '../../constants';
// services
import { setToken } from '@services/auth';
import {
  getUtmParamsMap,
  trackEvent,
  trackHeapUserDetails,
  trackIdentify,
  trackTikTokUserDetails,
} from '@services/tracking/tracking';
import { formatGraphQLError } from '@services/format';
import { getCookie, removeCookie, setCookie } from '@services/cookies';
// constants
import { GRAPH_QL_ERROR_TYPES } from '@constants/enums';
import { COOKIE_EXPIRES_IN, COOKIES } from '@constants/cookies';

// styles
import styles from './magic.module.scss';
import { useCheckLogInAsync } from '@src/hooks/useCheckLogInUser';

// utils
import { extractAndRemoveParams } from '../../utils/url';
import { isSSR } from '@src/services/global';

const Magic: React.FC = ({}) => {
  const location = useLocation();

  const { url: magicLoginUrl, extractedParams: magicLoginParams } = extractAndRemoveParams(location.search, ['token', 'basketId', 'destination']);
  const { url: redirectUrl, extractedParams: redirectParams } = extractAndRemoveParams(magicLoginParams.destination, ['promoteGuestConsultation', 'consultationVariant']);

  const { checkLogInUser, loading: loadingLoginUser } = useCheckLogInAsync();
  const { setErrorModalMessage } = useContext(GlobalContext);

  const navigateToDestination = () => {

    const url = redirectUrl.toString() ?? '/account/dashboard';
    navigate(url, {
      state: {
        previousPath: location.pathname,
        isPreviousPathMagicSignIn: true,
      },
      replace: true,
    });
  };

  const [
    mergeGuestBasket,
    { loading: loadingMergeGuestBasket, error: errorMergeGuestBasket },
  ] = useMutation(BASKET_MERGE_GUEST, {
    onCompleted: () => {
      removeCookie(COOKIES.basketId);
    },
  });

  const [
    promoteGuestConsultation,
    { data: dataPromoteGuestConsultation, loading: loadingPromoteGuestConsultation, error: errorPromoteGuestConsultation }
  ] = useMutation(PROMOTE_GUEST_CONSULTATION, {
    variables: {
      guestId: redirectParams.promoteGuestConsultation,
      variant: redirectParams.consultationVariant,
    },
    onCompleted: () => {
      navigateToDestination();
    },
    onError: error => {
      const invalidToken = error?.graphQLErrors.find(
        (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.InvalidToken
      );
      const invalidGuestId = error?.graphQLErrors.find(
        (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.InvalidGuestId
      );
      const hpGenerationFailed = error?.graphQLErrors.find(
        (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.HealthProfileGenerationFailed
      );
      const expectedError = invalidToken || invalidGuestId || hpGenerationFailed;
      if (expectedError && !isSSR) {
        window.history.replaceState(null, "My Vitl", "/account/signin")
      }
      if (hpGenerationFailed) {
        setErrorModalMessage(ERROR_MESSAGES.invalidToken);
      } else if (invalidGuestId) {
        setErrorModalMessage(ERROR_MESSAGES.invalidGuestIdForPromotion);
      } else if (hpGenerationFailed) {
        setErrorModalMessage(ERROR_MESSAGES.healthProfileGenerationFailed);
      }
    },
  });

  const [
    exchangeTempToken,
    { data: dataExchangeMagicToken, loading: loadingExchangeMagicToken, error: errorExchangeTempToken },
  ] = useMutation(MAGIC_SIGN_IN, {
    variables: {
      magicLinkToken: magicLoginParams.token,
      leadAttribution: getUtmParamsMap(),
    },
    onCompleted: async res => {
      const exchangedToken = res?.user_exchangeMagicLoginToken?.token;
      if (exchangedToken) {
        setToken(exchangedToken);
        const userDetails = await checkLogInUser();
        trackHeapUserDetails(userDetails.email, `${userDetails.firstName} ${userDetails.lastName}`);
        trackTikTokUserDetails(userDetails.email);

        const basketId = getCookie(COOKIES.basketId);
        const isGuestbasketId = basketId && basketId.toString().length === 32;

        if (isGuestbasketId) mergeGuestBasket({ variables: { basketId } });

        if (redirectParams.promoteGuestConsultation && redirectParams.promoteGuestConsultation !== '') {
          promoteGuestConsultation();
        } else {
          navigateToDestination();
        }

        trackIdentify(res.user_exchangeMagicLoginToken.customerDataPlatformId);
        trackEvent('Session Information', { is_logged_in: true });
      }
    },
    onError: error => {
      const invalidToken = error?.graphQLErrors.find(
        (error: any) => error.errorType === GRAPH_QL_ERROR_TYPES.InvalidToken
      );
    },
  });

  useEffect(() => {
    console.log("running useEffect: ", magicLoginParams.token, redirectParams.promoteGuestConsultation);
    if (redirectParams.basketId) setCookie(COOKIES.basketId, redirectParams.basketId, COOKIE_EXPIRES_IN.thirtyDays);
    if (magicLoginParams.token) exchangeTempToken();
  }, []);

  const isDataLoaded = dataExchangeMagicToken != null &&
    (redirectParams.promoteGuestConsultation == null || dataPromoteGuestConsultation != null);

  const isLoading =
    loadingExchangeMagicToken ||
    loadingMergeGuestBasket ||
    loadingLoginUser ||
    loadingPromoteGuestConsultation;

  const anyError = errorExchangeTempToken || errorMergeGuestBasket || errorPromoteGuestConsultation;

  return (
    <section>
      <h2>Login into your Vitl account</h2>
      <hr />
      {isDataLoaded && (
        <div>
          <p>You are being redirected</p>
          <Loader />
        </div>
      )}
      {!isDataLoaded && isLoading &&
        <Loader />
      }
      {anyError && (
        <div>
          <p>{formatGraphQLError(anyError)}</p>
          <Link to="/account/signin" className={styles.buttonPrimary}>
            Go to login page
          </Link>
        </div>
      )}
    </section>
  );
};

export default Magic;
